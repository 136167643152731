import React from 'react'
import Layout from '../components/layout2'
import Container from '../components/container'
import Case from '../components/case'
import Vbox from '../components/vbox'
import Top from '../components/top'
import Summary from '../components/summary'
import Button from '../components/button'
import Img from '../components/img'

class RootIndex extends React.Component {
  render() {
 
    return <Layout>
      
      <Container>
        <Vbox widegap>
          <Top 
            title="Internverktyg för Roder Innovation"
            body="En förbättring av gränssnittet."
            image="/roder.png"
          /> 
          <div>
            <h2 className="h2">1 - bakgrund & översikt: </h2>
            <Container narrow>
              <h3 className='h3'>Roder</h3>
              <p className="body">Mattias är techlead och front-end utvecklare på Roder Innovation. En dag, likt många andra på jobbet, sätter han sig för att analysera sina ljudenheter med det verktyg han själv har utvecklat. Till en början har han inte brytt sig om utseendet, men ju mer han använder verktyget, desto mer frustrerad blir han över att det varken är trevligt att använda eller se på. Han upplever följande problem:</p>
              <ul>
                <li>Det är svårt att få en överblick över veckans aktivitet</li>
                <li>Tabellerna är svårläsliga</li>
                <li>Det är svårt att särskilja olika states på enheterna</li>
              </ul>
            </Container>
          </div>
          <Summary>
            <h4 className="h4">Roll</h4>
            <p className="small">UI designer</p>
            <h4 className="h4">Kund</h4>
            <p className="small">Roder Innovation</p>
            <h4 className="h4">Uppdrag</h4>
            <p className="small">Att förbättra och göra ny gränssnittsdesign för ett internverktyg.
            Ett projekt jag gjorde utanför skolan.</p>
            <h4 className="h4">Tidsram</h4>
            <p className="small">4 arbetsdagar (2024) <br />
            Ett projekt jag gjorde utanför skolan.</p>
            <h4 className="h4">Resultat</h4>
            <p className="small">- En dashboard för överblick av veckans aktivitet <br />
- En ny tabell <br />
- En ny översikt över enheterna och deras state</p>
          </Summary>
          <div>
            <h2 className="h2">2 - resultat:</h2>
            
            <Img narrow src="/roder1.png" />
            <Img narrow src="/roder2.png" />
            <Img narrow src="/roder3.png" />
          </div>
          
          
          <div>
            <h2 className="h2">3 - före:</h2>
            <Img narrow src="/roder4.png" />
            <Img narrow src="/roder5.png" />
            <Img narrow src="/roder6.png" />
          </div>
        
          
          <div>
            <h2 className="h2">fler projekt:</h2>
            <div className='more-wrapper'>
              <div class="more">
                <img src='/jhl.png' />
                <h3 className='h3'>Jagharläst.se</h3>
                <Button to="/jagharlast">Utforska hela projektet {"->"}</Button>
              </div>
              <div class="more">
                <img src='/forum.png' />
                <h3 className='h3'>Bibliotek Forum</h3>
                <Button to="/forum">Utforska hela projektet {"->"}</Button>
              </div>
            </div>
          </div>
        </Vbox>
      </Container>
    </Layout>
    
    
  }
}

export default RootIndex