import React from 'react'

import styles from './footer.module.css'
import { Link } from 'gatsby'

export default ({ data }) => (
  <footer>
    <svg className={styles.wave} viewBox="0 0 1440 127" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M-1092.09 88.5L-719.786 97.7873C-692.819 98.46 -665.841 97.0171 -639.1 93.4717L-184.099 33.1473L164.165 22.1032C197.322 21.0517 230.486 23.9136 262.973 30.6294L487.943 77.1368C557.062 91.4256 628.378 91.3824 697.479 77.0099L1013.97 11.1824C1074.95 -1.50106 1137.73 -3.03899 1199.26 6.64299L1402.31 38.5953C1423.38 41.9105 1444.7 43.3417 1466.02 42.8719L1907.43 33.1473L2464.62 18.3527V127H-1092.09V88.5Z" fill="#EFEBE6"/>
</svg>

        <div className={styles.inner}>
            <Link to="/">
                <svg width="46" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895429 0 0 0.895431 0 2V18.6037C0 18.7079 0.00797636 18.8103 0.0233521 18.9102C0.0244499 18.9174 0.0189469 18.9238 0.011727 18.9238C0.00525035 18.9238 0 18.9291 0 18.9356V44C0 45.1046 0.89543 46 2 46H5.37126C6.47583 46 7.37125 45.1046 7.37125 44V22.6037C7.37125 21.4991 8.26668 20.6037 9.37125 20.6037H14.9531V14.5439H9.21784C8.11327 14.5439 7.21784 13.6485 7.21784 12.5439V8.06003C7.21784 6.95546 8.11327 6.06003 9.21783 6.06003H16.0444C17.1489 6.06003 18.0444 6.95546 18.0444 8.06003V12.1953H18.0458V23H18.0403V43.8931C18.0403 44.9976 18.9357 45.8931 20.0403 45.8931H23.4115C24.5161 45.8931 25.4115 44.9976 25.4115 43.8931V22.6037C25.4115 21.4991 26.307 20.6037 27.4115 20.6037H31.3927C32.3233 20.6037 33.1309 21.2455 33.3411 22.152L38.5129 44.4618C38.7284 45.3915 39.5707 46.0394 40.5246 46.0091L43.9454 45.9007C45.2109 45.8606 46.1212 44.6695 45.8275 43.4378L40.0577 19.2397C39.8854 18.5169 40.1403 17.7687 40.6393 17.2182C42.2965 15.3899 43.3061 12.9638 43.3061 10.3018C43.3061 4.61229 38.6938 0 33.0043 0H2ZM27.2618 6.06003H31.8464C34.1891 6.06003 36.0883 7.95921 36.0883 10.302C36.0883 12.6447 34.1891 14.5439 31.8464 14.5439H27.2618C26.1572 14.5439 25.2618 13.6485 25.2618 12.5439V8.06003C25.2618 6.95546 26.1572 6.06003 27.2618 6.06003Z" fill="black"/>
                </svg>
            </Link>
            <div>
                <h4>Kontakta mig</h4>
                <div className={styles.nav}>
                    <Link target='_blank' to="https://www.linkedin.com/in/frida-runnakko-0024a928a/">
                        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.5 0.5C20.2969 0.5 21 1.20312 21 2.04688V20C21 20.8438 20.2969 21.5 19.5 21.5H1.45312C0.65625 21.5 0 20.8438 0 20V2.04688C0 1.20312 0.65625 0.5 1.45312 0.5H19.5ZM6.32812 18.5V8.51562H3.23438V18.5H6.32812ZM4.78125 7.10938C5.76562 7.10938 6.5625 6.3125 6.5625 5.32812C6.5625 4.34375 5.76562 3.5 4.78125 3.5C3.75 3.5 2.95312 4.34375 2.95312 5.32812C2.95312 6.3125 3.75 7.10938 4.78125 7.10938ZM18 18.5V13.0156C18 10.3438 17.3906 8.23438 14.25 8.23438C12.75 8.23438 11.7188 9.07812 11.2969 9.875H11.25V8.51562H8.29688V18.5H11.3906V13.5781C11.3906 12.2656 11.625 11 13.2656 11C14.8594 11 14.8594 12.5 14.8594 13.625V18.5H18Z" fill="black"/>
                        </svg> Frida Runnakko
                    </Link>         
                    <a target='_blank' href="mailto:frida.runnakko@gmail.com">
                        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.25 0H21.75C22.9688 0 24 1.03125 24 2.25C24 3 23.625 3.65625 23.0625 4.07812L12.8906 11.7188C12.3281 12.1406 11.625 12.1406 11.0625 11.7188L0.890625 4.07812C0.328125 3.65625 0 3 0 2.25C0 1.03125 0.984375 0 2.25 0ZM0 5.25L10.1719 12.9375C11.25 13.7344 12.7031 13.7344 13.7812 12.9375L24 5.25V15C24 16.6875 22.6406 18 21 18H3C1.3125 18 0 16.6875 0 15V5.25Z" fill="black"/>
                        </svg> frida.runnakko@gmail.com
                    </a>     
                    <a target='_blank' href="tel:+46704186212">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.6875 2.17188L9.5625 6.67188C9.89062 7.42188 9.70312 8.3125 9.04688 8.82812L6.75 10.75C8.29688 14.0312 10.9688 16.7031 14.25 18.25L16.1719 15.9531C16.6875 15.2969 17.5781 15.1094 18.3281 15.4375L22.8281 17.3125C23.7188 17.6406 24.1406 18.625 23.9062 19.5156L22.7812 23.6406C22.5469 24.4375 21.8438 25 21 25C9.375 25 0 15.625 0 4C0 3.15625 0.5625 2.45312 1.35938 2.21875L5.48438 1.09375C6.375 0.859375 7.35938 1.28125 7.6875 2.17188Z" fill="black"/>
                    </svg>
                    +46704186212
                    </a>
                </div>
            </div>
        </div>  

  </footer>
)
