import React from 'react'
import styles from './top.module.css'

export default ({ children, title, body, image }) => (
  <div className={styles.top} >
        <div>
          <h1 className='h1'>{title}</h1>
          <p className='intro'>{body}</p>
        </div>
        <div className={styles.img} style={{backgroundImage: `url(${image})`}} />
  </div> 
)
